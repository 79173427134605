import styles from './ChartsSidebar.module.css';
import ChartsSidebarField from './../../atoms/chartsSidebarField/ChartsSidebarField';
import ChartsSidebarHeader from '../../atoms/chartsSidebarHeader/ChartsSidebarHeader';
import ChartsSideBarFilters from '../chartsSideBarFilters/ChartsSideBarFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
const ChartsSidebar = ({ className, content, currentWeek, currentActiveWeek, setCurrentActiveWeek, currentYear, setCurrentYear, currentActiveYear, setCurrentActiveYear }) => {
    const modelList = (modelList) => {
        const newModelList = new Map()
        Object.entries(modelList).sort((a, b) => {
            const aModelName = a[0].split(" - ")[0];
            const bModelName = b[0].split(" - ")[0];
            if ((aModelName < bModelName) || (aModelName === bModelName && a[1] > b[1])) { return -1 }
            else if ((aModelName > bModelName) || (aModelName === bModelName && a[1] < b[1])) { return 1 }
            return 0
        }).forEach(model => {
            const modelName = model[0].split(" - ")[0]
            if (newModelList.has(modelName)) {
                let oldList = newModelList.get(modelName);
                let oldTotal = oldList.get("total");
                let newTotal = oldTotal + parseInt(model[1])
                oldList.set("total", newTotal)
                oldList.set(model[0], model[1])
                newModelList.set(modelName, oldList)
            } else {
                let displaysList = new Map()
                displaysList.set("total", model[1])
                displaysList.set(model[0], model[1])
                newModelList.set(modelName, displaysList)
            }
        })
        return newModelList
    }
    modelList(content.totalModels)

    const RenderDisplayList = ({ modelList, modelName }) => {
        const [activeDisplay, setActiveDisplay] = useState(false)
        const renderLabel = (labelText) => {
            return (
                <span>{labelText} <FontAwesomeIcon className={styles.modelIcon} onClick={() => setActiveDisplay(!activeDisplay)} icon={["fas", activeDisplay ? "chevron-down" : "chevron-up"]} /></span>
            )
        }
        return (<div> <ChartsSidebarField label={renderLabel(modelName)} value={modelList.get("total")} />
            {activeDisplay &&
                [...modelList.entries()].map(([type, value]) => {
                    if (type !== "total") {
                        return <ChartsSidebarField className={styles.inner} label={type.split(" - ")[1]} value={value} />
                    }
                })}
        </div>)

    }
    return (
        <ul className={[styles.container, className].filter(e => !!e).join(' ')}>
            <ChartsSideBarFilters currentYear={currentYear} setCurrentYear={setCurrentYear} currentActiveYear={currentActiveYear} setCurrentActiveYear={setCurrentActiveYear} currentWeek={currentWeek} currentActiveWeek={currentActiveWeek} setCurrentActiveWeek={setCurrentActiveWeek} className={styles.sideBarContainer} />
            <div className={styles.spacer}></div>
            <div className={styles.sideBarContainer}>
                <span className={styles.sideBarHeader}>Info</span>
                <ChartsSidebarHeader title={"Displays connected"} subTitle={"amount"} />

                <ChartsSidebarField label={"Online:"} value={content.totalDisplaysOnline} />
                <ChartsSidebarField label={"Offline:"} value={content.totalDisplaysOffline} />

                <ChartsSidebarHeader title={"Model types"} subTitle={"amount"} />
                {
                    [...modelList(content.totalModels)].map(([displayType, list]) => {
                        return <RenderDisplayList modelName={displayType} modelList={list} />
                    })

                }
            </div>
        </ul>
    );
}

export default ChartsSidebar;