import styles from "./SidebarButton.module.css"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal } from "@fortawesome/pro-light-svg-icons";
library.add(fal);

function SidebarButton({ buttonText, iconName }) {
    return (
        <li className={[styles.sidebarButton].filter(e => !!e).join(' ')}><FontAwesomeIcon icon={["fal", iconName]} className={styles.icon} />{buttonText}</li>
    )
}

export default SidebarButton;