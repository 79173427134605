import Display from "../display/Display";
import styles from "./Content.module.css";
import TableBodyCell from '../../molecules/tableBodyCell/TableBodyCell';
import Customer from "../../atoms/customer/Customer";
import { useEffect, useState } from "react";

const Content = ({ isCustomer, isMultiSelectActive, content, name, description, onClick, display, onSelectedChange, displayLocation, companyId, handleDisplayChanges, role, className, showStatus, locations, firstRow, company, isUpdatingNew, getCompany, checkAll, locationChecked, isContextMenuVisible, ...props }) => {
    const [isSelected, setIsSelected] = useState(false);
    const [isRowHovered, setIsRowHovered] = useState(false)
    const [updateState, setUpdateState] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const delay = ms => new Promise(res => setTimeout(res, ms));
    useEffect(() => {
        isUpdatingNew && setUpdateState(true);
    }, [isUpdatingNew])
    useEffect(() => {
        !isMultiSelectActive && setIsSelected(false);
        onSelectedChange && onSelectedChange(isSelected, display);
    }, [isMultiSelectActive, onSelectedChange, isSelected, display, checkAll]);

    useEffect(() => {
        if (showPopup) {
            delay(5000).then(() => {
                setShowPopup(false)
            })
        }
    }, [showPopup])
    // const switchUpdate = () => {
    //     setUpdateState(!updateState)
    // }

    const hasPopUpText = (fieldName) => {
        return (fieldName === "status" && display.last_online !== null) ? ["lastOnline", display.last_online] : undefined
    }
    useEffect(() => {
        (isMultiSelectActive === true && checkAll) ? setIsSelected(true) : setIsSelected(false);
    }, [checkAll, isMultiSelectActive])

    useEffect(() => {
        (isMultiSelectActive === true && locationChecked) ? setIsSelected(true) : setIsSelected(false);
    }, [locationChecked, isMultiSelectActive])
    return (
        <div onMouseEnter={() => setIsRowHovered(true)} onMouseLeave={() => setIsRowHovered(false)} className={[styles.row, isSelected && styles.selected, className].filter(e => !!e).join(' ')} onClick={() => { console.log(display, isCustomer); display?.connectionStatus || isCustomer ? onClick() : setShowPopup(true) }} {...props}>
            {isCustomer ? <Customer className={styles.title} name={name} isSelected={isSelected} /> : <Display isContextMenuVisible={isContextMenuVisible} isHovered={isRowHovered} hasStatus={showStatus} role={role} {...props} locations={locations} company={company} companyId={companyId} display={display} displayLocation={displayLocation} isMultiSelectActive={isMultiSelectActive} className={styles.title} onSelectedChange={() => setIsSelected(!isSelected)} isSelected={isSelected} handleDisplayChanges={handleDisplayChanges} />}
            {

                content && Object.entries(content).map((field) => {
                    return <TableBodyCell isUpdating={updateState} firmware={field[0] == 'firmware' ? display.firmware : undefined} newFirmware={field[0] == 'firmware' ? display.latest_version : undefined} firstRow={firstRow} serial={field[0] == 'firmware' ? display.serialNumber : ""} isSelected={field[0] === 'status' && isSelected} isRowHovered={isRowHovered} hasText={hasPopUpText(field[0])} isOnline={content?.status?.props?.isOnline} hasSphereUpdate={(field[0] == 'firmware' && content.firmware < display.latest_version) ? true : false} key={field[0]}> {(field[1] !== null && field[1] !== undefined && field[1] !== "") ? field[1] : '-'} </TableBodyCell>
                    // return <TableBodyCell getCompany={getCompany} firstRow={firstRow} isUpdating={updateState} firmware={field[0] == 'firmware' ? display.firmware : undefined} serial={field[0] == 'firmware' ? display.serialNumber : ""} isSelected={field[0] === 'status' && isSelected} isRowHovered={isRowHovered} hasText={ hasPopUpText(field[0])} isOnline={content?.status?.props?.isOnline} hasSphereUpdate={(field[0] == 'firmware' && (content.firmware < display.latest_version || display.is_updating )) ? true : false} key={field[0]}> {(field[1] !== null && field[1] !== undefined && field[1] !== "") ? field[1] : '-'} </TableBodyCell>
                })
            }
            <div className={[styles.emptyBox, isSelected && styles.selected].filter(e => !!e).join(' ')} />
        </div >
    );
}

Content.defaultProps = {
    isCustomer: false
}

export default Content;