import React, { Fragment, useRef, useContext } from 'react';
import Sidebar from "../../ui/molecules/sidebar/Sidebar";
import styles from "./DisplaySettings.module.css";
import Display from "./../../ui/organisms/display/Display";
import BusinessName from "../../ui/molecules/businessName/BusinessName";
import SettingsHeader from "../../ui/atoms/settingsHeader/SettingsHeader";
import SettingsTitle from "./../../ui/molecules/settingsTitle/SettingsTitle";
import SettingsSidebar from "./../../ui/molecules/settingsSidebar/SettingsSidebar";
import SettingsRow from "../../ui/molecules/settingsRow/SettingsRow";
import { Input, RadioGroup } from "@ctouch-europe-b-v/react-component-library";
import HelpMenu from "./../../ui/organisms/helpMenu/HelpMenu";
import useSearch from "./../../../hooks/UseSearch";
import { useEffect, useState } from "react";
import { useWebSocket } from "../../../hooks/UseWebSocket";
import LocationRow from "../../ui/organisms/locationRow/LocationRow";
import { DisplaySettingsService } from "../../../services/DisplaySettingsService";
import { useLocation } from "react-router-dom";
import SettingsLinkHeader from "../../ui/atoms/settingsLinkHeader/SettingsLinkHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextNote from './../../ui/atoms/textNote/TextNote';
import WarningModal from '../../ui/organisms/warningModal/WarningModal';
import PowerTimer from '../../ui/organisms/powerTimer/PowerTimer';
import { LocationContext, FilterContext } from '../../../contexts/LocationContext';
import useFilters from './../../../hooks/UseFilters';
import useSort from "../../../hooks/UseSort";
import { AppManagementService } from '../../../services/AppManagementService';
import AppList from '../../ui/molecules/appList/AppList';
import AppListRow from '../../ui/atoms/appListRow/AppListRow';
import BetaFeature from '../../ui/atoms/betaFeature/BetaFeature';

const DisplaySettings = ({ role }) => {
  const { search } = useSearch();
  const [query, setQuery] = useState("");
  const [settings, setSettings] = useState([]);
  const { sortList } = useSort()
  const { filter } = useFilters()
  const [sort, setSort] = useState(null);
  const location = useLocation();
  const [filters, setFilters] = useState(location.state.activeFilters.filters !== null ? location.state.activeFilters.filters : [{ 'model': [] }]);
  const [locationFilters, setLocationFilters] = useState(location.state.activeFilters.locationFilters !== null ? location.state.activeFilters.locationFilters : [{ 'location_name': [] }]);
  const [currentDisplay, setCurrentDisplay] = useState(location.state.display);
  const [settingList, setSettingList] = useState([]);
  const [activeMenu, setActiveMenu] = useState("Quick Menu");
  const [inLink, setInLink] = useState(false);
  const isMultiSelectActive = useRef(false);
  const [selected, setSelected] = useState([]);
  const [locations, setLocations] = useState([]);
  const [allChecked, setAllChecked] = useState();
  const [apps, setApps] = useState([]);
  const { send, joinRoom, restoreToDefault, configExport, update } = useWebSocket();
  const exceptions = ["muteChange", "ecoChange", "freezeChange", "blmChange"];
  const noFirstRowPages = ["Android Apps"];
  const [isShutdownActive, setIsShutdownActive] = useState(false);
  const [isRestoreToDefaultActive, setIsRestoreToDefaultActive] = useState(false);
  const [isUpdateActive, setIsUpdateActive] = useState(false)
  const restoreToDefaultRef = useRef();
  const shutdownRef = useRef();

  const { locationList, setLocationList } = useContext(LocationContext);
  const { globalFilterList, setGlobalFilterList } = useContext(FilterContext)


  useEffect(() => {
    globalFilterList.forEach(field => {
      Object.entries(field).forEach(([key, values]) => {
        key !== 'location_name' ? setFilters(prev => {
          prev = prev.map(filter => { if (filter[key] !== undefined) { filter[key] = values }; return filter })
          return prev;
        })
          :
          setLocationFilters((prev) => {
            prev = prev.map(filter => { if (filter[key] !== undefined) { filter[key] = values }; return filter })
            return prev
          })
      })
    })
  }, [globalFilterList])

  useEffect(() => {
    setInLink(noFirstRowPages.includes(activeMenu));
    setLocations(locationList);
    setSettingList([]);
    setSettingList([...loadSettings(settings)]);
  }, [settings, activeMenu, query]);

  useEffect(() => {
    let newLocationList = JSON.parse(JSON.stringify(location.state.company.locations))
    newLocationList = filter(newLocationList, locationFilters);
    newLocationList.forEach((location) => {
      location.displays = filter(location.displays, filters);
      location.displays = sort === null ? location.displays : sortList(location.displays, sort[1], (item) => item[sort[0]])
      return location.displays
    });
    setLocationList(newLocationList);
    setLocations(newLocationList)
  }, [filters, locationFilters])

  useEffect(() => {
    DisplaySettingsService.getAllSettings(currentDisplay)
      .then((response) => {
        setSettings(response.data);
      })
      .then(() => {
        setSettingList([...loadSettings(settings)]);
      });
    AppManagementService.getAllApps(currentDisplay.id).then(response => {
      setApps(response.data);
    })
    joinRoom(currentDisplay.serialNumber)
    !currentDisplay.model.includes("A12") && configExport(currentDisplay.serialNumber);
  }, [currentDisplay, selected]);

  useEffect(() => {
    locations.forEach((location) => {
      selectAllInLocation(allChecked, location)
    })
  }, [allChecked])

  const handleFilterChange = (newFilters, existingFilters) => {

    setGlobalFilterList((prevList) => {
      return prevList.map(filter => {
        if (Object.keys(newFilters).includes(Object.keys(filter)[0])) {
          filter[Object.keys(filter)[0]] = newFilters[Object.keys(filter)[0]];
        }
        return filter;
      })
    })
    const nextFilters = existingFilters.map((filter) => {

      if (Object.keys(newFilters).includes(Object.keys(filter)[0])) {
        filter[Object.keys(filter)[0]] = newFilters[Object.keys(filter)[0]];
      }
      return filter;
    });
    if (newFilters.location_name) { setLocationFilters(nextFilters) }
    else { setFilters(nextFilters) };

  }

  const generateDevicePage = (device) => {
    const displayData = [[
      { presentation_name: "Device model", setting_value: currentDisplay.model, type: "note" },
      { presentation_name: "Serial number", setting_value: currentDisplay.serialNumber, type: "note" },
    ], [
      { presentation_name: "LAN MAC Address", setting_value: currentDisplay.mac_address, type: "note" },
      { presentation_name: "LAN IP Address", setting_value: currentDisplay.lan_ip_address, type: "note" },
    ], [
      { presentation_name: "Firmware version", setting_value: currentDisplay.firmware, type: "note" },
      { presentation_name: "RS232 version", setting_value: currentDisplay.rs_232_version, type: "note" },
    ]];
    device.settings = displayData[0];
    for (let index = 0; index < device.children.length - 1; index++) {
      const child = device.children[index];
      child.settings = displayData[index + 1];
    }
    return device;
  }

  const generateAppsPage = () => {
    return (
      <>
        <BetaFeature />
        <AppListRow></AppListRow>
        <AppList appList={apps} serial={currentDisplay.serialNumber} />
      </>)
  }


  const sendSetting = (name, value, setting_id, deviation_id) => {
    if (name === "Backlight_Mute") {
      if (value === "Off") value = "On";
      else value = "Off";
    }
    if (name === "Energy Mode") {
      if (value === "Off") value = "Normal"
      if (value === "On") value = "Ecofriendly"
    }
    if (name === "Touch" && currentDisplay.serialNumber.includes("CN")) {
      if (value === "Off") value = "On"
      else value = "Off"
    }
    if (deviation_id === "renameSourceLabel" && value !== '') {
      value = [{ "Source": name, "Label": value }];
      name = 'SourceLabel';
    }
    if (isMultiSelectActive.current === true) {
      selected.forEach((display) => {
        joinRoom(display.serialNumber);
        send(
          name,
          value,
          display.serialNumber,
          setting_id
        );
      })
    } else {
      send(
        name,
        value,
        currentDisplay.serialNumber,
        setting_id
      );
    }
  }

  const settingRow = (setting, index) => {
    return setting.type === 'execute' && !currentDisplay.model.includes("R2") ? <SettingsRow
      hasWarning={setting.presentation_name.includes("Update") ? false : true}
      hasUpdate={setting.presentation_name.includes("Update") ? true : false}
      title={setting.presentation_name}
      subTitle={setting.sub_text}
      key={setting.presentation_name}
      isClickable
      onClick={() => { setting.presentation_name === "Shutdown" ? setIsShutdownActive(true) : (setting.presentation_name.includes("Update")) ? setIsUpdateActive(true) : setIsRestoreToDefaultActive(true) }}
    >
      <FontAwesomeIcon icon={["fal", "circle-arrow-right"]} />
    </SettingsRow> : <SettingsRow key={setting.presentation_name} title={setting.presentation_name} className={styles.radioGroup} subTitle={setting.sub_text}>
      {getInput(setting)}
    </SettingsRow>
  }

  const getInput = (setting) => {
    let input;
    switch (setting.type) {
      case "radio":
        input = <RadioGroup options={setting.options ? setting.options.map((option) => option.presentation_value) : []} value={setting.setting_value} onChange={(e) => {
          sendSetting(
            setting.name,
            setting.options.find((option) => option.presentation_value === e.target.value).value,
            setting.id,
            setting?.deviation_id
          );
        }} />
        break;
      case "note":
        input = <TextNote value={setting.setting_value} />
        break;
      case "text":
        input = <Input inputType={setting.type} placeholder={setting.setting_value} onBlur={(e) => { sendSetting(setting.name, e.target.value, setting.id, setting?.deviation_id) }} />
        break;
      default:
        input = <Input
          inputType={setting.type === "negative_slider" ? 'slider' : setting.type}
          bidirectional={setting.type === "negative_slider"}
          value={
            setting.type === "switch" || setting.type === "checkbox"
              ? parseToBool(setting.setting_value)
              : setting.setting_value
          }
          min={setting.min_value}
          max={setting.max_value}
          onMouseUp={(e) => {
            e.stopPropagation();
            if (setting.type !== "switch" && setting.type !== "checkbox" && setting.type !== "dropDown") {
              sendSetting(
                setting.name,
                e.target.value,
                setting.id,
                setting?.deviation_id
              );
            }
          }}
          onChange={(e) => {
            if (setting.type === "switch" || setting.type === "checkbox" || setting.type === "dropDown") {
              sendSetting(
                setting.name,
                setting.type === "dropDown" ? setting.options.find((option) => option.presentation_value === e.target.value).value : parseFromBool(e.target.checked, setting.name),
                setting.id,
                setting?.deviation_id
              );
            }
          }}
          options={setting.options && setting.options.map((option) => option.presentation_value)}
        />
        break;
    }
    return input;
  }

  const parseToBool = (value, name) => {
    let bool;
    if (exceptions.includes(name)) {
      bool = value === "on";
      return bool;
    }
    bool = value === "On";
    return bool;
  };

  const parseFromBool = (value, name) => {
    if (exceptions.includes(name)) {
      if (value === true) return "on";
      return "off";
    }
    if (value === true) return "On";
    return "Off";
  };

  function handleMultiSelectActiveChange() {
    isMultiSelectActive.current = !isMultiSelectActive.current;
    setSelected([]);
  }

  const selectAllInLocation = (isSelected, location) => {
    if (location?.displays) {
      location.displays.forEach((display) => {
        if ((selected.indexOf(display) < 0 || !isSelected) && checkMultiSelectAvailability(display) === true) {
          onSelectedChange(isSelected, display)
        }
      })
    }
  }

  const onSelectedChange = (isSelected, display) => {
    if (isSelected) {
      selected.push(display)
    } else {
      selected.splice(selected.indexOf(display), 1);
    }
    setSelected(selected);
  };

  const displayContent = (displayLocation) => {
    const displays = displayLocation.displays.map((display, index) => {
      return (
        <Display
          isContextMenuVisible={!isMultiSelectActive.current}
          onClick={() =>
            (display.connectionStatus) && setCurrentDisplay(display)
          }
          className={[
            styles.display,
            currentDisplay.id === display.id && styles.active,
          ]
            .filter((e) => !!e)
            .join(" ")}
          key={index}
          companyId={location.state.company.id}
          checkAll={allChecked}
          isMultiSelectActive={checkMultiSelectAvailability(display)}
          onSelectedChange={(e) => onSelectedChange(e, display)}
          locations={locations}
          display={display}
          displayLocation={displayLocation}
          role={role}
          hasStatus
          company={location.state.company}
        />
      )
    });
    return displays;
  };

  const checkMultiSelectAvailability = (display) => {
    if (isMultiSelectActive.current) {
      if (!display.connectionStatus) {
        return "Offline"
      }
      return true
    }
    return null;
  };

  const locationContent = locations.map((location, index) => {
    return (
      <LocationRow checkAll={allChecked} isMultiSelectActive={isMultiSelectActive.current} key={index} locationTitle={location.location_name} onLocationCheckedChange={(e) => selectAllInLocation(e, location)}>
        {displayContent(location)}
      </LocationRow>
    )
  });

  const loadSettings = (allSettings) => {
    let settingsList;
    let currentSettings = [];
    let searchResult = [];
    let result = [];
    allSettings.forEach((cat) => {
      if (query !== "") {
        return cat.children.map((menuCat) => {
          currentSettings = currentSettings.concat(menuCat.settings);
          return menuCat.children.map(
            (child) => (currentSettings = currentSettings.concat(child.settings))
          );
        });
      } else {
        settingsList = cat.children.filter(
          (setting) => setting.name === activeMenu
        );
        if (settingsList.length > 0) {
          result = settingsList.map((activeMenu) => {
            return loadSettingContent(activeMenu);
          });
        }
      }
    });
    if (query !== "") {
      searchResult = search(currentSettings.map((setting) => setting), query, ["presentation_name"]);
      result = searchResult.map((setting, index) => {
        return (
          settingRow(setting, index)
        );
      });
    }
    return result;
  };

  const loadSettingContent = (settingsList) => {
    let children = [];
    if (settingsList.name === "Device") {
      settingsList = generateDevicePage(settingsList);
    }
    if (settingsList.name === "Android Apps") {
      return generateAppsPage();

    }
    const menuSettings = settingsList.settings.map((setting, index) => {
      return (
        settingRow(setting, index)
      );
    });
    const menuHeaders = settingsList.children.map((setting, index) => {
      if (setting.sub_type === null) { return; } // temporarily hide this until setting (In this case rename source) works again
      if (setting.sub_type === "header") {
        if ((setting.children !== undefined && setting.children.length > 0) || (setting.settings !== undefined && setting.settings.length > 0)) {
          children = loadSettingContent(setting).filter(child => child !== undefined);
          if (children.length > 0) {
            return (
              <>
                <SettingsHeader key={index} title={setting.name} />
                {children}
              </>
            );
          }
        }
      } else if (setting.sub_type === "link") {
        if (setting.children.length > 0 || setting.settings.length > 0) {
          if (setting.name === "Power On" || setting.name === "Power Off") {
            return (
              <SettingsRow
                title={setting.name}
                isClickable
                onClick={() => {
                  const header = [
                    <SettingsLinkHeader
                      title={setting.name}
                      onLeave={() => {
                        setInLink(false)
                        setSettingList([[...loadSettings(settings)]])
                      }}
                    />,
                  ]
                  setInLink(true)
                  setSettingList(

                    header.concat(
                      <PowerTimer type={setting.name}
                        timer={setting.settings} onTimerChange={(timer) => {
                          console.log(timer);
                          timer.forEach(item => sendSetting(item.name, item.value, item.id, setting?.deviation_id));
                          setInLink(false); setSettingList([...loadSettings(settings)])
                        }} />
                    )
                  )
                }}
              >
                <FontAwesomeIcon icon={["fal", "circle-arrow-right"]} />
              </SettingsRow>
            )
          }
          return (
            <SettingsRow
              title={setting.name}
              isClickable
              onClick={() => {
                const header = [
                  <SettingsLinkHeader
                    title={setting.name}
                    onLeave={() => {
                      setInLink(false);
                      setSettingList([...loadSettings(settings)]);
                    }}
                  />,
                ];
                setInLink(true);
                setSettingList(
                  header.concat(
                    setting.settings.map((setting, index) => (
                      settingRow(setting, index)
                    )),
                    setting.children.map((setting) => {
                      if ((setting.children !== undefined && setting.children.length > 0) || (setting.settings !== undefined && setting.settings.length > 0)) {
                        return (
                          <>
                            <SettingsHeader key={index} title={setting.name} />
                            {loadSettingContent(setting)}
                          </>
                        )
                      }
                    }
                    )
                  )
                );
              }}
            >
              <FontAwesomeIcon icon={["fal", "circle-arrow-right"]} />
            </SettingsRow >
          );
        }
      } else {
        return (
          <SettingsRow
            title={setting.name}
            isClickable
            onClick={() => {
              const header = [
                <SettingsLinkHeader
                  title={setting.name}
                  onLeave={() => {
                    setInLink(false);
                    setSettingList([...loadSettings(settings)]);
                  }}
                />,
              ];
              setInLink(true);
              setSettingList(
                header.concat(
                  setting.settings.map((setting, index) => (
                    settingRow(setting, index)
                  ))
                )
              );
            }}
          >
            <FontAwesomeIcon icon={["fal", "circle-arrow-right"]} />
          </SettingsRow>
        );
      }
    });
    return menuSettings.concat(menuHeaders);
  };

  return (<Fragment>
    <div className={styles.mainContainer}>
      <Sidebar role={role} sphereUser={role} className={styles.sidebar} />
      <div className={styles.displays}>
        <BusinessName
          isMultiSelectVisible={true}
          filters={location.state.filters}
          updateActiveFilters={(activeFilters) => { handleFilterChange({ 'location_name': activeFilters.location_name }, locationFilters); handleFilterChange({ 'model': activeFilters.model }, filters) }}
          onSelectedChange={(e) => setAllChecked(e)}
          setSort={(state) => setSort(["displayName", state])}
          isMultiSelectActive={isMultiSelectActive.current}
          multiChange={handleMultiSelectActiveChange}
          businessName={location.state.company.name}
          className={styles.businessName}
          displaySettingsClassName={styles.displaySettingsClassName}
        />
        <span className={styles.displayContent}>{locationContent}</span>
        {/* <FooterSideButton /> */}
      </div>
      <div className={styles.settingsContainer}>
        <SettingsTitle
          filters={location.state.filters}
          status={currentDisplay.connectionStatus}
          onSearchChange={(e) => setQuery(e.target.value)}
          className={styles.header}
          title={currentDisplay.name}
          subtitle={currentDisplay.model}
          companyId={location.state.company.id}
        />
        {currentDisplay.connectionStatus == 1 ?
          <>
            <SettingsSidebar
              catList={settings}
              className={styles.settingsSidebar}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              model={currentDisplay.model.split(" - ")[0]}
            />
            <span className={styles.settingItems}>
              {!inLink && <SettingsRow />}
              {settingList}
            </span>
            <div className={styles.settingsFooter}>
              <HelpMenu className={styles.helpMenu} />
            </div>
          </>
          :
          <span className={styles.offlineMessage}>Your display is offline!</span>
        }
      </div>
      {isRestoreToDefaultActive && <WarningModal onClick={(e) => e.stopPropagation()} innerRef={restoreToDefaultRef} headerText={"Restore to default?"} bodyText={"Restoring the display to factory defaults will also disconnnect the display from the CTOUCH Sphere portal."} textBtn1={"Cancel"} textBtn2={"Restore"} onClickBtn1={(e) => { e.stopPropagation(); setIsRestoreToDefaultActive(false) }} onClickBtn2={(e) => { e.stopPropagation(); restoreToDefault(currentDisplay); setIsRestoreToDefaultActive(false) }} />}
      {isShutdownActive && <WarningModal onClick={(e) => e.stopPropagation()} innerRef={shutdownRef} headerText={"Are you sure?"} bodyText={"You are choosing to Shutdown."} textBtn1={"Back"} textBtn2={"Shutdown"} onClickBtn1={(e) => { e.stopPropagation(); setIsShutdownActive(false) }} onClickBtn2={(e) => { e.stopPropagation(); sendSetting('Power', 'Off', null, null); setIsShutdownActive(false) }} />}
      {isUpdateActive && <WarningModal onClick={(e) => e.stopPropagation()} innerRef={shutdownRef} headerText={"Are you sure?"} bodyText={"Are you sure you want to update the firmware of this screen."} textBtn1={"Back"} textBtn2={"Update"} onClickBtn1={(e) => { e.stopPropagation(); setIsUpdateActive(false) }} onClickBtn2={(e) => { e.stopPropagation(); update(currentDisplay.serialNumber); setIsUpdateActive(false) }} />}
    </div>
  </Fragment>
  );
};

export default DisplaySettings;
