import styles from "./ChartsPage.module.css";
import Sidebar from './../../ui/molecules/sidebar/Sidebar';
import BusinessName from './../../ui/molecules/businessName/BusinessName';
import { Button, ButtonColor } from '@ctouch-europe-b-v/react-component-library';
import ChartsSidebar from "../../ui/molecules/chartsSidebar/ChartsSidebar";
import usePDFExport from "../../../hooks/UsePDFExport";
import { useEffect, useRef, useState } from "react";
import { DashboardService } from "../../../services/DashboardService";
import { CustomerService } from './../../../services/CustomerService';
import HelpMenu from "../../ui/organisms/helpMenu/HelpMenu";
import ChartsContentContainer from "../../ui/atoms/chartsContentContainer/ChartsContentContainer";
import ChartWidgetDataSmall from "../../ui/molecules/chartWidgetDataSmall/ChartWidgetDataSmall";
import ChartWidgetTextSmall from "../../ui/molecules/chartWidgetTextSmall/ChartWidgetTextSmall";
import ChartWidgetListSmall from "../../ui/molecules/chartWidgetListSmall/ChartWidgetListSmall";
import ChartWidgetTextMedium from "../../ui/molecules/chartWidgetTextMedium/ChartWidgetTextMedium";
import ChartWidgetListMedium from "../../ui/molecules/chartWidgetListMedium/ChartWidgetListMedium";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardChart from "../../ui/atoms/dashboardChart/DashboardChart";
import ChartWidgetGraphLarge from "../../ui/molecules/chartWidgetGraphLarge/ChartWidgetGraphLarge";
import DisplayInfoPopUp from "../../ui/atoms/displayInfoPopUp/DisplayInfoPopUp";

Date.prototype.getWeek = function (currentDate) {
   let date = currentDate !== undefined ? new Date(this.getTime()) : currentDate.getTime();
   date.setHours(0, 0, 0, 0);
   // Thursday in current week decides the year.
   date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
   // January 4 is always in week 1.
   let week1 = new Date(date.getFullYear(), 0, 4);
   // Adjust to Thursday in week 1 and count number of weeks from date to week1.
   return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

// Returns the four-digit year corresponding to the ISO week of the date.
Date.prototype.getWeekYear = function () {
   let date = new Date(this.getTime());
   date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
   return date.getFullYear();
}


function getDateBasedOnWeekDifference(originalDate, weekDifference) {
   var newDate = new Date(originalDate); // Create a new date object based on the original date

   // Calculate the number of milliseconds in a week
   var millisecondsInWeek = 7 * 24 * 60 * 60 * 1000;

   // Calculate the difference in milliseconds based on the difference in week numbers
   var millisecondsDifference = weekDifference * millisecondsInWeek;

   // Add or subtract the calculated difference to get the new date
   newDate.setTime(newDate.getTime() - millisecondsDifference);

   return newDate;
}

const date = new Date()

const ChartsPage = ({ role, company }) => {
   const { exportToPDF } = usePDFExport();
   const [charts, setCharts] = useState();
   const [isLoading, setIsLoading] = useState(true)
   const [currentCompany, setCurrentCompany] = useState({});
   const [overLayActive, setOverLayActive] = useState(false);
   const [currentWeek, setCurrentWeek] = useState([])
   const [currentActiveWeek, setCurrentActiveWeek] = useState([]);
   const [currentYear, setCurrentYear] = useState();
   const [currentActiveYear, setCurrentActiveYear] = useState();
   const [currentDate, setCurrentDate] = useState(date.toISOString().split("T")[0])
   const ref = useRef();
   const summaryRef = useRef();
   const energyRef = useRef();
   const usageRef = useRef();
   const firmwareRef = useRef();
   const appsChartRef = useRef();
   const screenChartRef = useRef();
   const energyChartRef = useRef();
   const firmwareChartRef = useRef();
   useEffect(() => {
      getCompany()
      getData()
      DashboardService.getData(company, currentDate).then((response) => {
         setCharts(response.results);
         setIsLoading(false)
      });
   }, [company])
   useEffect(() => {
      if (currentActiveYear < currentYear) {
         let yearDiff = currentYear - currentActiveYear;
         let weekDiff = ((52 - currentActiveWeek) + currentWeek) * yearDiff;
         const newDate = getDateBasedOnWeekDifference(date, weekDiff);
         setCurrentDate(newDate.toISOString().split("T")[0])

      } else {
         let weekDiff = currentWeek - currentActiveWeek;
         const newDate = getDateBasedOnWeekDifference(date, weekDiff);
         setCurrentDate(newDate.toISOString().split("T")[0])
      }
   }, [currentActiveWeek, currentDate, currentWeek])

   useEffect(() => {
      console.log(currentDate)
      DashboardService.getData(company, currentDate).then((response) => {
         console.log(response.results)
         setCharts(response.results);
         setIsLoading(false)
      });
   }, [currentDate])

   const getCompany = () => {
      CustomerService.getOne(company).then((response) => {
         setCurrentCompany(response.data)
      });
   }

   const getData = () => {
      const currentDate = new Date();
      setCurrentDate(currentDate.toISOString().split('T')[0])
      const currentWeek = currentDate.getWeek(currentDate);
      const currentYear = currentDate.getWeekYear();
      setCurrentWeek(currentWeek)
      setCurrentActiveWeek(currentWeek)
      setCurrentYear(currentYear);
      setCurrentActiveYear(currentYear)
   }

   const quickTips = ["Neo and Riva R2 feature presence detection to automatically turn off.", "You can use Eco Mode to reduce energy usage.", "Our displays still look great at less than 100% brightness!"]

   return (
      (!isLoading)
         ? <div className={styles.mainContainer}>
            {overLayActive && <div onClick={() => setOverLayActive(false)} className={styles.overlay}></div>}
            <Sidebar className={styles.sidebar} role={role} sphereUser={role} />
            <BusinessName className={styles.company} hasFilters={false} businessName={currentCompany.name} />
            <div className={styles.contentHeader}><Button variant={ButtonColor.blue} iconName={"file-export"} title="Export to PDF" onClick={() => { const date = new Date(); exportToPDF([summaryRef.current, energyRef.current, usageRef.current, firmwareRef.current], `${currentCompany.name}-week${currentWeek}-${date.toISOString().split('T')[0]}`, true); }} /></div>
            <ChartsSidebar currentYear={currentYear} setCurrentYear={setCurrentYear} currentActiveYear={currentActiveYear} setCurrentActiveYear={setCurrentActiveYear} currentWeek={currentWeek} currentActiveWeek={currentActiveWeek} setCurrentActiveWeek={setCurrentActiveWeek} className={styles.filters} content={charts.count_list} />
            <div className={styles.content} ref={ref}>
               <ChartsContentContainer ref={summaryRef} title={"summary"} titleIcon={"sigma"} >
                  <ChartWidgetDataSmall
                     title={"Screen on"}
                     className={styles.widgetContainer}
                     colorVariant={"green"}
                     iconLeft={{ type: "fas", name: "power-off" }}
                     iconRight={{ type: "fas", name: "arrow-right" }}
                     hasError={charts.usageData === undefined}
                     hasWarning={charts?.usageData?.weekData.length === 0}
                     unit={charts?.usageData?.averageHours}
                     unitType={"hours"}
                     difference={charts?.usageData?.hoursComparedToLastWeek}
                     pageLinkRef={screenChartRef}
                     iconRightClassName={styles.pageLink}>
                     {charts.usageData !== undefined && charts.usageData.weekData.length !== 0
                        ? <DashboardChart currentDate={currentDate} colorVariant={"white"} fill={true} size={"small"} dataAreDates={true} type={"line"} data={charts.usageData.weekData} />
                        : charts.usageData !== undefined && charts.usageData.weekData.length === 0
                           ? <span className={styles.warning}>There is not enough data to display this information...</span>
                           : <span className={styles.error}>Something went wrong displaying this data...</span>
                     }
                  </ChartWidgetDataSmall>
                  <ChartWidgetDataSmall
                     title={"Apps"}
                     colorVariant={"grey"}
                     className={[styles.widgetContainer, styles.appLaunchesSummaryContainer].filter(e => !!e).join(" ")}
                     iconLeft={{ type: "fas", name: "gamepad" }}
                     iconRight={{ type: "fas", name: "arrow-right" }}
                     unit={charts?.usageData?.appLaunches.sort((a, b) => a.value < b.value ? 1 : a.value > b.value ? -1 : 0)[0]?.value}
                     unitType={"launches"}
                     hasError={charts.usageData === undefined}
                     hasWarning={charts?.usageData?.appLaunches.length === 0}
                     difference={charts?.usageData?.sourcesComparedToLastWeek}
                     pageLinkRef={appsChartRef}
                     iconRightClassName={styles.pageLink}>
                     {charts.usageData !== undefined && charts.usageData.appLaunches.length !== 0
                        ? <div className={styles.appLaunchesSummary}><FontAwesomeIcon icon={["fas", "trophy"]} /> {charts.usageData.appLaunches[0]?.name.replaceAll('"', "")}<DisplayInfoPopUp className={styles.popup} >{charts.usageData.appLaunches[0]?.name.replaceAll('"', "")}</DisplayInfoPopUp></div>
                        : charts.usageData !== undefined && charts.usageData.appLaunches.length === 0
                           ? <span className={styles.warning}>There is not enough data to display this information...</span>
                           : <span className={styles.error}>Something went wrong displaying this data...</span>
                     }
                  </ChartWidgetDataSmall>
                  <ChartWidgetDataSmall
                     title={"Energy"}
                     colorVariant={"yellow"}
                     className={styles.widgetContainer}
                     iconLeft={{ type: "fas", name: "leaf" }}
                     iconRight={{ type: "fas", name: "arrow-right" }}
                     hasError={charts.energyData === undefined}
                     unit={charts?.energyData?.comparedToAverage.toString().replace("%", "")}
                     unitType={"percent"}
                     difference={charts?.energyData?.comparedToLastWeek}
                     hasWarning={charts?.energyData?.weekData.length < 2}
                     pageLinkRef={energyChartRef}
                     iconRightClassName={styles.pageLink}>
                     {charts.energyData !== undefined && charts.energyData.weekData.length >= 2
                        ? <DashboardChart currentDate={currentDate} size={"small"} colorVariant={"black"} fill={true} valueDataType={"percent"} dataAreDates={true} type={"line"} data={charts.energyData.weekData} />
                        : charts.energyData !== undefined && charts.energyData.weekData.length < 2
                           ? <span className={styles.warning}>There is not enough data to display this information...</span>
                           : <span className={styles.error}>Something went wrong displaying this data...</span>}
                  </ChartWidgetDataSmall>
                  <ChartWidgetDataSmall
                     title={"Firmware"}
                     colorVariant={"blue"}
                     className={styles.widgetContainer}
                     iconLeft={{ type: "fas", name: "arrows-rotate" }}
                     iconRight={{ type: "fas", name: "arrow-right" }}
                     hasError={charts.firmwareData === undefined}
                     unit={charts?.firmwareData?.displaysToUpDate.length}
                     unitType={"displays"}
                     pageLinkRef={firmwareRef}
                     iconRightClassName={styles.pageLink}>
                     {charts.firmwareData !== undefined && charts.firmwareData.displaysToUpDate !== undefined
                        ? <div className={styles.firmwareSummary}>{charts.firmwareData.displaysToUpDate.length} Out of Date</div>
                        : <span className={styles.error}>Something went wrong displaying this data...</span>}
                  </ChartWidgetDataSmall>
               </ChartsContentContainer>
               <div className={styles.spacer}></div>
               <ChartsContentContainer ref={energyRef} title={"energy"} titleIcon={"leaf"} >
                  <ChartWidgetGraphLarge
                     title={"Consumption"}
                     hasWarning={charts.energyData !== undefined && (charts.energyData.weekData.length < 2)}
                     hasError={charts.energyData === undefined}
                     colorVariant={"grey"}
                     iconLeft={{ type: "fas", name: "fire" }}
                     chartRef={energyChartRef}
                  >
                     {charts.energyData !== undefined && charts.energyData.weekData.length >= 2
                        ? <DashboardChart currentDate={currentDate} chartId={"consumptionChart"} size={"large"} colorVariant={"green"} stepAmount={10} dataAreDates={true} type={"line"} valueDataType={"percent"} data={charts.energyData.weekData} />
                        : charts.energyData !== undefined && charts.energyData.weekData.length < 2
                           ? <span className={styles.warning}>There is not enough data to display this information...</span>
                           : <span className={styles.error}>Something went wrong displaying this data...</span>
                     }
                  </ChartWidgetGraphLarge>
                  <ChartWidgetTextMedium
                     title={"Summary"}
                     colorVariant={"yellow"}
                     className={styles.widgetContainer}
                     data={{ average: charts?.energyData?.comparedToAverage, comparedToLastWeek: charts?.energyData?.comparedToLastWeek, dataList: charts?.energyData?.energyPerDisplayData }}
                     setOverLayActive={(val) => setOverLayActive(val)}
                     overLayActive={overLayActive}
                     plusIsPositive={false}
                     containsPlus={true}
                     hasError={charts.energyData === undefined}
                     hasWarning={charts.energyData !== undefined && (charts.energyData.weekData.length === 0 || charts.energyData.comparedToLastWeek === "NaN%" || charts.energyData.comparedToAverage === "NaN%")}
                     textVariant={"summaryEnergy"}
                     iconLeft={{ type: "fas", name: "sigma" }}
                     hasFullView={true} />
                  <div className={styles.smallEnergyContainer}>
                     <ChartWidgetTextSmall
                        title={"Quick Tip"}
                        colorVariant={"blue"}
                        className={styles.widgetContainer}
                        texts={quickTips}
                        iconLeft={{ type: "fas", name: "lightbulb" }}
                        iconRight={{ type: "fal", name: "arrow-up-right-from-square" }} />
                     <ChartWidgetListSmall
                        title={"Rating"}
                        colorVariant={"grey"}
                        className={[styles.widgetContainer, styles.hasFullList].filter(e => !!e).join(" ")}
                        iconLeft={{ type: "fas", name: "trophy" }}
                        setOverLayActive={(val) => setOverLayActive(val)}
                        overLayActive={overLayActive}
                        plusIsPositive={false}
                        containsPlus={true}
                        hasError={charts.energyData === undefined}
                        hasWarning={charts.energyData !== undefined && charts.energyData.energyPerDisplayData.length === 0}
                        dataList={charts?.energyData?.energyPerDisplayData} />
                  </div>
               </ChartsContentContainer>
               <div className={styles.spacer}></div>
               <ChartsContentContainer ref={usageRef} title={"usage"} titleIcon={"gamepad"} >
                  <ChartWidgetListMedium
                     title={"App Launches"}
                     colorVariant={"grey"}
                     className={styles.widgetContainer}
                     iconLeft={{ type: "fas", name: "gamepad" }}
                     setOverLayActive={(val) => setOverLayActive(val)}
                     overLayActive={overLayActive}
                     hasError={charts.usageData === undefined}
                     hasWarning={charts.usageData !== undefined && charts.usageData.appLaunches.length === 0}
                     dataList={charts?.usageData?.appLaunches}
                     chartRef={appsChartRef} />
                  <ChartWidgetTextMedium
                     title={"Summary"}
                     colorVariant={"green"}
                     className={styles.widgetContainer}
                     data={{ average: charts?.usageData?.averageHours, comparedToLastWeek: charts?.usageData?.hoursComparedToLastWeek, dataList: charts?.usageData?.hoursPerDisplayData }}
                     setOverLayActive={(val) => setOverLayActive(val)}
                     overLayActive={overLayActive}
                     hasError={charts.usageData === undefined}
                     hasWarning={charts.usageData !== undefined && (charts.usageData.averageHours?.length === 0 || charts.usageData.hoursComparedToLastWeek?.length === 0 || charts.usageData.weekData?.length === 0)}
                     textVariant={"summaryEnergyUsage"}
                     iconLeft={{ type: "fas", name: "sigma" }}
                     hasFullView={true} />
                  <ChartWidgetGraphLarge
                     title={"Screen on Time"}
                     hasWarning={charts.usageData !== undefined && (charts.usageData.weekData.length === 0)}
                     hasError={charts.usageData === undefined}
                     colorVariant={"grey"}
                     iconLeft={{ type: "fas", name: "power-off" }}
                     chartRef={screenChartRef}>
                     {
                        charts.usageData !== undefined && charts.usageData.weekData.length !== 0
                           ? <DashboardChart
                              currentDate={currentDate}
                              size={"large"}
                              dataAreDates={true}
                              colorVariant={"green"}
                              chartId={"ScreenOnTime"}
                              type={"line"}
                              data={charts?.usageData?.weekData} />
                           : charts.usageData !== undefined && charts.usageData.weekData.length === 0
                              ? <span className={styles.warning}>There is not enough data to display this information...</span>
                              : <span className={styles.error}>Something went wrong displaying this data...</span>
                     }
                  </ChartWidgetGraphLarge>
                  <ChartWidgetGraphLarge
                     title={"Sources"}
                     colorVariant={"grey"}
                     hasWarning={charts.usageData !== undefined && (charts.usageData.averageSourceUsage.length === 0)}
                     hasError={charts.usageData === undefined}
                     iconLeft={{ type: "fas", name: "arrow-right-to-bracket" }}>
                     {charts.usageData !== undefined && charts?.usageData?.averageSourceUsage.length !== 0
                        ? <DashboardChart currentDate={currentDate} size={"large"} colorVariant={"blue"} valueDataType={"hour"} type={"bar"} data={charts.usageData.averageSourceUsage} />
                        : charts.usageData !== undefined && charts?.usageData?.averageSourceUsage.length === 0
                           ? <span className={styles.warning}>There is not enough data to display this information...</span>
                           : <span className={styles.error}>Something went wrong displaying this data...</span>
                     }
                  </ChartWidgetGraphLarge>
                  <ChartWidgetTextMedium
                     title={"Summary"}
                     colorVariant={"blue"}
                     className={styles.widgetContainer}
                     data={{ comparedToLastWeek: charts?.usageData?.sourcesComparedToLastWeek, dataList: charts?.usageData?.averageSourceUsage }}
                     hasError={charts.usageData === undefined || (charts.usageData.averageSourceUsage === undefined || charts.usageData.sourcesComparedToLastWeek === undefined)}
                     hasWarning={charts.usageData !== undefined && (charts.usageData.averageSourceUsage.length === 0 || charts.usageData.sourcesComparedToLastWeek.length === 0)}
                     textVariant={"summarySourceUsage"}
                     setOverLayActive={(val) => setOverLayActive(val)}
                     overLayActive={overLayActive}
                     iconLeft={{ type: "fas", name: "sigma" }}
                     hasFullView={true} />
               </ChartsContentContainer>
               <div className={styles.spacer}></div>
               <ChartsContentContainer ref={firmwareRef} title={"firmware"} titleIcon={"arrows-rotate"} >
                  <ChartWidgetTextMedium
                     colorVariant={"blue"}
                     className={styles.widgetContainer}
                     data={{ firmwareAmount: charts?.firmwareData?.displaysToUpDate.length, dataList: charts?.firmwareData?.displaysToUpDate }}
                     title={"Summary"}
                     hasError={charts.firmwareData === undefined}
                     textVariant={"summaryFirmware"}
                     setOverLayActive={(val) => setOverLayActive(val)}
                     overLayActive={overLayActive}
                     iconLeft={{ type: "fas", name: "sigma" }}
                     hasFullView={true}
                     chartRef={firmwareChartRef}
                  />
                  <ChartWidgetTextMedium
                     colorVariant={"grey"}
                     className={styles.widgetContainer}
                     data={{ dataList: charts?.firmwareData?.displaysToUpDate }}
                     title={"Updates"}
                     hasError={charts.firmwareData === undefined}
                     textVariant={"firmwareUpdates"}
                     setOverLayActive={(val) => setOverLayActive(val)}
                     overLayActive={overLayActive}
                     iconLeft={{ type: "fas", name: "arrow-up" }}
                     hasFullView={true} />
                  {/* <ChartWidgetTextSmall
                     colorVariant={"blue"}
                     className={styles.widgetContainer}
                     title={"Heartbeat SAFE"}
                     iconLeft={{ type: "fas", name: "heartbeat" }}
                     iconRight={{ type: "fal", name: "arrow-up-right-from-square" }}
                     rightIconOnClick={(() => {
                        window.open("https://ctouch.eu/heartbeat?__geom=%E2%9C%AA", "_blank")
                     })}
                     rightIconClassName={styles.iconLink}>
                     <span>Upgrade to SAFE to upgrade all displays at once! <a className={styles.textLink} href="https://ctouch.eu/heartbeat?__geom=%E2%9C%AA">Learn More!</a></span>
                  </ChartWidgetTextSmall> */}
               </ChartsContentContainer>
            </div>

            {/* <div className={styles.content} ref={ref}>
               <Chart iconName={"leaf"} title={"Sustainability"} type={ChartTypes.range} companyName={currentCompany.name} isBidirectional chartTitle={"ENERGY SAVED"} chartSubtitle={"Compared to Average Use"} value={charts.sustainability.energySaved} valueIndicator={"This month"} iconLabels={['leaf', 'bolt-lightning']} textLabels={['Low Energy', 'Average', 'High Energy']} className={styles.sustainability} />
               <Chart iconName={"download"} title={"Updates"} type={ChartTypes.range} companyName={currentCompany.name} chartTitle={"Devices with"} chartSubtitle={"The Latest Firmware"} value={charts.latest_firmware.upToDateDisplays} valueIndicator={charts.latest_firmware.totalDisplays} className={styles.updates} />
               <Chart iconName={"link-simple"} title={"Status"} type={ChartTypes.range} companyName={currentCompany.name} value={charts.connected_to_sphere.totalDisplaysOnline} valueIndicator={charts.connected_to_sphere.totalDisplays} chartTitle={"DEVICES Online"} chartSubtitle={"Connected to Sphere"} className={styles.status} />
               {/* <Chart iconName={"smile"} title={"Time online"} type={ChartTypes.numeric} companyName={companyInformation.companyName} className={styles.timeOnline} chartInfo={charts.hours_online} /> */}
            {/* <div className={styles.sourceHours}><SourceHoursChart chartData={charts.source_hours} companyName={companyInformation.companyName} /></div> */}
            {/* </div> */}
            <div className={styles.contentFooter}>
               <HelpMenu />
            </div>
         </div>
         : <div className={styles.mainContainer}>
            <Sidebar className={styles.sidebar} role={role} sphereUser={role} />
            <div className={[styles.company, styles.loadingCompany].filter(e => !!e).join(" ")}></div>
            <div className={styles.contentHeader}></div>
            <div className={styles.loadingSidebar}></div>
            <div className={styles.content}></div>
         </div>
   );
}

export default ChartsPage;