import SettingsSidebarHeader from "../../atoms/settingsSidebarHeader/SettingsSidebarHeader";
import SettingsSidebarRow from "../../atoms/settingsSidebarRow/SettingsSidebarRow";
import styles from "./SettingsSidebar.module.css"

const headerPermissions = {
    "App Management": "AndroidApps.View",
}

const SettingsSidebar = ({ className, onChange, activeMenu, setActiveMenu, catList, model }) => {
    const noAccessPagesPerModel = { "neo": ["CTOUCH Launcher", "Android Apps"], "d2": [] }
    const accessPagesPerModel = { "d2": ["Android Apps"], "r2": ["Android Apps"] }

    const sideBarIcon = (name) => {
        switch (name) {
            case "Quick Menu":
                return "smile";
            case "Admin Menu":
                return "glasses";
            case "Connectivity":
                return "wifi";
            case "Picture":
                return "tv"
            case "Sound":
                return "volume"
            case "General":
                return "signs-post"
            case "Device":
                return "tablet"
            case "Android Apps":
                return "grid-2";
            default:
                return "tablet";
        }
    }

    const popSideBar = (catList) => {
        return catList.map((cat, index) => {
            if (cat.children.length > 0 &&
                ((cat.children.filter(child => ((!noAccessPagesPerModel[model.toLowerCase()]?.includes(child.name)) && (child.children.length > 0 || child.settings.length > 0) || accessPagesPerModel[model.toLowerCase()]?.includes(child.name))).length > 0))
            ) {
                return <>
                    <SettingsSidebarHeader key={index} title={cat.name} />
                    {
                        cat.children.map((child, childIndex) => {
                            if ((child.settings.length > 0 || child.children.length > 0) || (accessPagesPerModel[model.toLowerCase()]?.includes(child.name))) {
                                return <SettingsSidebarRow key={childIndex} isActive={activeMenu === child.name} title={child.name} iconName={sideBarIcon(child.name)} onClick={() => { setActiveMenu(child.name); }} />
                            }
                        })
                    }
                </>
            }
        });

    }

    return (
        <div className={[styles.sidebar, className].filter(e => !!e).join(' ')}>
            <ul className={styles.list}>
                {popSideBar(catList)}
            </ul>
        </div>
    );
}

export default SettingsSidebar